import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import { ResponseResult } from '../../lib-shared/models/response-result';

@Injectable({
    providedIn: 'root',
})
export class ProductAdsCheckInService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/ProductAdsCheckIn`);
    }

    Gets(key: string, userId: number, idClient: number, idCustomer: number, idUserGroupTdv: number, idProductAds: number, idCheckInOut: number, idProvince: number, idCustomerType: number, idUserGroup: number, isActive: number, isAccepted: number, isPayReward: number, isRewarded: number, fromDate?: Date, toDate?: Date, totalSaleFrom?: number, totalSaleTo?: number, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }

        const queryString = `${this.serviceUri}?key=${key}&userId=${userId}&idClient=${idClient}&idCustomer=${idCustomer}&idUserGroupTdv=${idUserGroupTdv}&idProductAds=${idProductAds}&idCheckInOut=${idCheckInOut}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isActive=${isActive}&isAccepted=${isAccepted}&isPayReward=${isPayReward}&isRewarded=${isRewarded}&startDate=${fDate}&endDate=${tDate}&totalSaleFrom=${totalSaleFrom}&totalSaleTo=${totalSaleTo}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    ChangeAccepted(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/ChangeAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    DestroyAccepted(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/DestroyAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }


    MultiAccepted(ids: any, status: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/MultiAccepted?ids=' + ids + '&status=' + status, { ids: ids, status: status })
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    PayReward(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/PayReward/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    MultiPayReward(ids: any, status: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/MultiPayReward?ids=' + ids + '&status=' + status, { ids: ids, status: status })
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    ChangeActive(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/ChangeActive/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    UpdateDelete(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/UpdateDelete/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
